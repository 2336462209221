<template>
  <div>
    <div class="row">
      <div class="mb-3 col">
        <label
          for="exampleFormControlInput1"
          class="form-label"
        >Provide Company Name </label>
        <a-input
          v-model:value="item.carrier_name"
          type="text"
          class="form-control"
          @focus="clearError"
        />
        <div
          class="text-danger error"
          :class="{ show: error.carrier_name }"
        >
          {{ error.carrier_name }}
        </div>
      </div>
      <div class="mb-3 col">
        <label
          for="exampleFormControlInput1"
          class="form-label"
        >Provide Contact Person </label>
        <a-input
          v-model:value="item.contact_name"
          type="text"
          class="form-control"
          @focus="clearError"
        />
        <div
          class="text-danger error"
          :class="{ show: error.contact_name }"
        >
          {{ error.contact_name }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col">
        <label
          for="exampleFormControlInput1"
          class="form-label"
        >Phone Number </label>
        <a-input
          v-model:value="item.phone_number"
          type="text"
          class="form-control"
          @focus="clearError"
        />
        <div
          class="text-danger error"
          :class="{ show: error.phone_number }"
        >
          {{ error.phone_number }}
        </div>
      </div>
      <div class="mb-3 col">
        <label
          for="exampleFormControlInput1"
          class="form-label"
        >Email</label>
        <a-input
          v-model:value="item.email"
          type="text"
          class="form-control"
          @focus="clearError"
        />
        <div
          class="text-danger error"
          :class="{ show: error.email }"
        >
          {{ error.email }}
        </div>
      </div>
    </div>
    <a-row :gutter="24">
      <a-col :span="12">
        <a-form-item
          label="From"
          class="w-100 d-block"
        >
          <a-input
            v-model:value="item.tn_range_begin"
            type="number"
            class="w-100"
          />
          <div
            class="text-danger error"
            :class="{ show: error.tn_range_begin }"
          >
            {{ error.tn_range_begin }}
          </div>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item
          label="To"
          class="w-100 d-block"
        >
          <a-input
            v-model:value="item.tn_range_end"
            type="number"
            class="w-100"
          />
          <div
            class="text-danger error"
            :class="{ show: error.tn_range_end }"
          >
            {{ error.tn_range_end }}
          </div>
        </a-form-item>
      </a-col>
    </a-row>
    <div>
      <div class="mb-2">
        Upload LOA
      </div>
      <a-upload-dragger
        accept=".pdf, .png"
        :before-upload="beforeUpload"
        :multiple="false"
        @change="handleChange"
      >
        <p class="ant-upload-drag-icon">
          <inbox-outlined v-model:value="file" />
        </p>
        <p class="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p class="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading company data or
          other band files
        </p>
      </a-upload-dragger>
    </div>
    <div
      class="text-danger error"
      :class="{ show: error.file }"
    >
      {{ error.file }}
    </div>

    <div class="d-flex justify-content-end mt-3">
      <a
        href="https://docs.google.com/document/d/1bRf4ggRSnlzC0_6NoBagiv3VSf0dBmgri3nlj18-de8/edit?usp=sharing"
        target="_blank"
        class="btn btn-primary mr-3"
      >
        Download LOA Template
      </a>

      <button
        class="btn btn-success"
        @click="create"
      >
        Submit
      </button>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import axios from '@/axios'
import { useRouter } from 'vue-router'
export default {
  data() {
    return {
      item: {
        carrier_name: '',
        tn_range_begin: '',
        tn_range_end: '',
        contact_name: '',
        phone_number: '',
        email: '',
        loa_type : 'ALL',
        file: null,
      },
      error: {
        tn_range_begin: '',
        tn_range_end: '',
        file: '' || null,
      },
      router: useRouter(),
    }
  },
  methods: {
    hadleError() {
      let error = false
      for (const key in this.error) {
        if (!this.item[key]) {
          if (!error) error = true
          if (key == 'file') {
            this.error.file = 'Please upload a file'
          } else {
            this.error[key] = 'Please enter a value'
          }
        }
      }
      return error
    },
    clearError() {
      this.error = {
        carrier_name: '',
        tn_range_begin: '',
        tn_range_end: '',
        file: '',
      }
    },
    beforeUpload() {
      return false
    },
    handleChange(info) {
      this.item.file = info.file
    },
    create() {
      if (this.hadleError()) return
      axios
        .post('/signing/loa', this.item)
        .then((res) => {
          this.clearError()
          this.item =  {
            carrier_name: 'TALKIE COMMUNICATIONS, INC.',
                tn_range_begin: '',
                tn_range_end: '',
                contact_name: '',
                phone_number: '',
                email: '',
                loa_type : 'ALL',
                file: null,
          },
          Swal.fire({
            title: 'Success!',
            text: 'Load Created',
            icon: 'success',
            confirmButtonText: 'Ok',
          }).then((result) => {
            this.item =  {
              carrier_name: 'TALKIE COMMUNICATIONS, INC.',
              tn_range_begin: '',
              tn_range_end: '',
              contact_name: '',
              phone_number: '',
              email: '',
              loa_type : 'ALL',
              file: null,
            }
            if (result.value) {
              this.upload(res.data.id)
              this.router.push('/signing/loa')
            }
          })
        })
        .catch((err) => {
          const error = err.response.data.message
          let messages
          if (Array.isArray(error)) messages = [...err.response.data.message]
          else messages = err.response.data.message
          Swal.fire({
            title: 'Error!',
            text: messages,
            icon: 'error',
            confirmButtonText: 'Ok',
          })
        })
    },
    upload(id) {
      let formData = new FormData()
      console.log(this.item.file)
      if(!this.item.file) {
        this.hadleError()
        return
      }
      formData.append('file',this.item.file)
      axios
        .post(`/signing/loa/${id}/upload_file`, formData)
        .then((res) => {
          this.item =  {
            carrier_name: 'TALKIE COMMUNICATIONS, INC.',
            tn_range_begin: '',
            tn_range_end: '',
            contact_name: '',
            phone_number: '',
            email: '',
            loa_type : 'ALL',
            file: null,
          },
          this.clearError()
          Swal.fire({
            title: 'Success!',
            text: 'File Uploaded',
            icon: 'success',
            confirmButtonText: 'Ok',
          }).then((result) => {
            this.item =  {
              carrier_name: 'TALKIE COMMUNICATIONS, INC.',
              tn_range_begin: '',
              tn_range_end: '',
              contact_name: '',
              phone_number: '',
              email: '',
              loa_type : 'ALL',
              file: null,
            }
            if (result.value) {
              this.router.push('/signing/loa')
            }
          })
        })
        .catch((err) => {
          console.log(err)
          const error = err.response.data.message
          let messages
          if (Array.isArray(error)) messages = [...err.response.data.message]
          else messages = err.response.data.message
          Swal.fire({
            title: 'Error!',
            text: messages,
            icon: 'error',
            confirmButtonText: 'Ok',
          })
        })
    },
  },
}
</script>
<style>
.ant-upload-list-item-name {
  color: #00a45b!important;
}
.anticon-paper-clip {
  color: #00a45b!important;
}
</style>

<style scoped>
.error {
  opacity: 0;
  height: 14px;
  margin-top: 5px;
}
.show {
  opacity: 1;
  font-size: 12px;
}
</style>